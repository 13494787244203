// -----------------------------------------------------------------------------
// This file contains all styles related to the masthead of the site/application.
// -----------------------------------------------------------------------------

.page-header{
  margin: 0; /* Reset bootstrap styles*/
  border: none; /* Reset bootstrap styles*/
	position: relative;
	padding: 30px 0;
	color: #fff;
	//background-repeat: no-repeat;
  background-repeat: repeat-y;
	background-position: center 50%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  background-color: $color-primary;

/*  
  background-image: -moz-linear-gradient(left, #001324 0%, #005081 100%);
  background-image: -webkit-gradient(left top, right top, color-stop(0%, #001324), color-stop(100%, #005081));
  background-image: -webkit-linear-gradient(left, #001324 0%, #005081 100%);
  background-image: -o-linear-gradient(left, #001324 0%, #005081 100%);
  background-image: -ms-linear-gradient(left, #001324 0%, #005081 100%);
  background-image: linear-gradient(to right, #001324 0%, #005081 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001324', endColorstr='#005081', GradientType=1 );
*/
  background-image: -moz-linear-gradient(left, #001a33 0%, #009bfb 100%);
  background-image: -webkit-gradient(left top, right top, color-stop(0%, #001a33), color-stop(100%, #009bfb));
  background-image: -webkit-linear-gradient(left, #001a33 0%, #009bfb 100%);
  background-image: -o-linear-gradient(left, #001a33 0%, #009bfb 100%);
  background-image: -ms-linear-gradient(left, #001a33 0%, #009bfb 100%);
  background-image: linear-gradient(to right, #001a33 0%, #009bfb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a33', endColorstr='#009bfb', GradientType=1 );
  z-index: 1;
  
  &:after {
    background: rgba(0, 0, 0, 0.35);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  h1, .h1{
    font-weight: bold;
    margin: 0;
    line-height: 1;
	  color: #fff;
  }
  p{
    &:last-child{
      margin-bottom: 0;
    }
    &.lead{ 
      margin-top:20px;
      color: #fff;
    }
  }
  .lead p {
    margin-top:20px;
    color: #fff;
  }
  @media (min-width: 768px){
    padding: 60px 0;
  }
  @media (min-width: 992px){
    padding: 90px 0;
  }
  @media (min-width: 1200px){
    padding: 100px 0;
  }
}

.page-header.transparent{
  background-color: #fff;
  background-image: none;
  //padding-bottom: 60px;
  color: $color-primary;
  &:not(.with-padding-bottom) {
    padding-bottom: 0;
  }
    
  &:after {
    background: rgba(0, 0, 0, 0);
  }
   h1, .h1{
     color: $color-primary;
  }
  p{
    &.lead{ 
      color: $color-primary-mid;
    }
  }
  
}

#about .page-header{
  background-image: url(../images/about/correa-building-new.jpg);
}
#about-group .page-header{
  background-image: url(../images/about/correa-entrada-new.jpg);
}
#about-group-subsidiaries .page-header{
  background-image: url(../images/about/correa-entrada-new.jpg);
}
#about-rdi .page-header{
  background-image: url(../images/about/rdi-header.jpg);
}

#header-app-services.page-header{
  background-image: url(../images/services/app-services-header.jpg);
}
#header-sales-services.page-header{
  background-image: url(../images/services/sales-services-header.jpg);
}
#trabaja{
  .page-header{
    &.transparent{
      padding-bottom: 50px;
    }
  }
}

#milling-heads .page-header{
  background-image: url(../images/milling-heads/cabezal-header.jpg);
  /*background-size: auto;*/
  &:after {
    background: rgba(0, 38, 61, 0.23);
  }
  @media (min-width: 768px){
    padding: 100px 0 130px;
    p.lead, .lead p{
      font-size: 28px;
      opacity: 1;
      margin-bottom: 30px;
      line-height: 1.4em;
    }
    p{
      opacity: 0.7;
      font-size: 20px;
      line-height: 1.6em;
    }
    ul{
      padding-left: 1rem;
      margin-bottom: 1rem;
      li {
        opacity: 0.7;
        font-size: 20px;
        line-height: 1.6em;
        list-style: disc;
      }
    }
    .text {
      margin-top: 30px;
    }
  }
}
