// -----------------------------------------------------------------------------
// This file contains all styles related to the forms of the site/application.
// -----------------------------------------------------------------------------

.form .form-group {
	margin-bottom: 24px;
}

.form label{
  font-weight: 500;
  font-family: $font-primary;
  letter-spacing: 0.01em;
  margin-bottom: 8px;
}

label.checkbox-inline, label.radio-inline{
  font-weight: 400;
}

.form-control{
	-webkit-appearance: none; /* for box shadows to show on iOS */
}

.form .form-control{
  border: 1px solid #fff;
  border-radius: 0px;
  box-shadow: none;
  height: 44px;
  padding: 10px 14px;
  
  &:focus{
    box-shadow: none;
    border:1px solid #ddd;
  }
}

.form textarea.form-control{
	 height: auto;
}

.form .checkbox{
	font-size: 15px;
  margin-bottom: 24px;
  a{
    color: $color-text;
    @include on-event{
      color: $color-primary-mid;
    }
  }
}
.form button.btn-lg{
	width: 200px;
  &.btn-block{
    width: 100%;
  }
}

button{
   @include transition(all 0.3s ease);
}

.form .help-block {
	font-size: 14px;
  line-height: 1.4em;
  //margin-top: 15px;
  font-style: italic;
}

/**
 * Bootstrap Select - Reset styles
 */

.bootstrap-select{
  .btn.dropdown-toggle{
    white-space: nowrap;
    padding: 10px 25px 10px 14px;
    font-weight: 400;
    letter-spacing: normal;
    
    &:after{
      content: none;
      margin: 0;
    }
     &:focus,  &:active{
      box-shadow: none;
      outline: 0 none !important;
    }
  }
  .dropdown-menu{
    font-size: 15px;
  }
  .dropdown-menu > li > a{
    padding: 8px 14px;
    color: $color-text;
  }
}


/**
 * Fancy form
 */

.fancy-form .form-group {
	//margin-bottom: 30px;
  
  label:not(.show){
    opacity: 0;
    font-size: 15px; 
    color: #999;
    margin-bottom: 0;
  }
   &.input-filled {
     label{
      opacity: 1;
      transition: all 0.25s ease;
    }
  }
}

.fancy-form .form-control {
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid $color-primary;
	border-radius: 0;
	box-shadow: none;
	padding: 6px 0;
	font-style: normal;
  font-size: 17px;
  color: $color-dark;
	height: 40px;
  
  &::-moz-placeholder{
    color: $color-text;
    opacity: 1;
    font-size: 16px; 
  }

  &::-webkit-input-placeholder{
    color: $color-text;
    opacity: 1;
    font-size: 16px; 
  }
  
  &:-ms-input-placeholder{
    color: $color-text;
    opacity: 1;
    font-size: 16px; 
  }
    
  &::placeholder{
    color: $color-text;
    opacity: 1;
    font-size: 16px; 
  }
  
  &:focus, &.has-error:focus{
    transition: transform 0.3s ease 0s;
    border-bottom: 1px solid $color-primary-light;
    box-shadow: 0 1px 0 0 $color-primary-light;
    border-right: none;
    border-left: none;
    border-top: none;
    
    &::-moz-placeholder{
      opacity:0;
    }
    &::-webkit-input-placeholder{
      opacity:0;
    }
    &:-ms-input-placeholder{
      opacity:0;
    }
    &::placeholder{
      opacity:0;
    }
  }
}
.fancy-form{
  .has-error .form-control:focus{
    border-color: $color-primary-light;
  }
}


.fancy-form.dark-form{
  .form-control{
    border-bottom: 1px solid #999;
    color: #eee;

    &::-moz-placeholder{
      color: #888;
    }
    &::-webkit-input-placeholder{
    color: #888;
    }

    &:-ms-input-placeholder{
      color: #888;
    }

    &::placeholder{
      color: #888;
    }
    &:focus{
       border-bottom: 1px solid $color-primary-light;
    }
  }
}
.fancy-form {
  .bootstrap-select .btn.dropdown-toggle{
    border: none;
    border-bottom: 1px solid $color-primary;
    box-shadow: none !important;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 0;
    color: $color-text;
    background-color: transparent !important;
    
    &:hover, &:focus{
      background-color: transparent;
    }
  }
  .btn-default.active, .btn-default:active, .btn-default:hover, .open > .btn-default.dropdown-toggle{
    border: none;
    background-color: transparent;
    color: $color-text;
  }
}

.fancy-form.form-lateral{
  .form-group{
    margin-bottom: 10px;
    label{
      font-size: 14px; 
    }
  }
  .form-control{
    height: auto;
    padding: 2px 0 4px;
  }
}

/**
 * Form validation
 */

label.error {
  font-weight: normal;
  color:#DD3D3D;
  font-size:14px;
  font-family: $font-primary;
}
.has-error .form-error{
  color: #d5031c;
}
.has-error .help-block{
  color: $color-text;
  
  &.form-error{
    color: #d5031c;
  }
}
.alert > p, .alert > ul{
  line-height: normal;
}
.alert{
  border-radius: 3px; 
  font-size: 15px;
  padding: 1.2em;
  
  p:last-child{
    margin-bottom:0;
  }
}
.alert-danger{
  background-color: #d5031c;
  border-color: #d5031c;
  color: #fff;
  text-align:center;
}
.alert-success{
  background-color: #00bf81;
  border-color: #00bf81;
  color: #fff;
  text-align:center;
}

/**
 * Section forms
 */

.section-form{
  .section-form-header{
    text-align: center;
    margin-bottom: 60px;
  }
  .title{
    font-family: $font-secondary;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: -0.03em;
    font-weight: bold;
    margin-top:0;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 600px){
      font-size: 26px;
    }
  }
  .form{
    margin-top: 40px;
  }
  @include when-inside('.sidebar'){
    .title{
      text-align: left;
      @media (max-width: 600px){
        font-size: 26px;
      }
    }
  }
}

/**
 * Bottom form
 */

.form-bottom{
  padding: 6em 0;
}

/**
 * Box-form
 */

.box-form{
  background-color: #fff;
  padding: 3em;
  
  @media (min-width: 992px) and (max-width: 1199px){
    padding: 3em 2em;
  }
  @media (max-width: 480px){
    padding: 3em 2em;
  }
  
  .title{
    text-align: left;
    @media (max-width: 600px){
      font-size: 26px;
    }
  }
  @include when-inside('.sidebar'){
    padding: 0;
  }
}



/**
 * Foro Registro form
 */



#form-registro{
  h3 {
    border-bottom: 1px solid $color-primary-mid;
    font-size: 20px;
    font-weight: 700;
    margin-top:60px;
    margin-bottom: 40px;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: $color-primary-mid;
  }
  .form-group {
    margin-bottom: 40px;
  }

  label{
    color: $color-primary;
    font-weight: 600;
    display: block;
  }
}

#form-trabaja{
  .msg-error-tn, .msg-success-tn
  {
    margin-top: 10px;
  }
}


