// -----------------------------------------------------------------------------
// This file contains styles that are specific to the News page.
// -----------------------------------------------------------------------------

/**
 * List - News
 */

.list-news{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }
  
  .post{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .post-info{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  &.row{
    &:before, &:after{
      content: normal;
    }
  }
}

.post{
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  
  figure{
    display: block;
    position: relative;
    overflow:hidden;
    width: 100%;
    z-index: 9;
    img{
      backface-visibility: hidden;
      transform: scale(1);
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
      margin: 0 auto;
    }
  }

  .post-info{
    padding: 26px 30px;
    background: #fff;
    border-bottom: 2px solid #E8E8E8;
    border-top: 1px solid #eee;
    font-size: 15px;
    line-height: 1.5em;
    
    .meta{
      line-height: 1.4em;
      font-size: 14px;
      margin-bottom: 6px;
      letter-spacing: 0.4pt;
    }
    .title{
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
      margin: 0; 
      a{
        color: $color-primary;
        @include on-event {
          color: $color-primary-mid;
        }
      }
    }
    .entry{
      //font-size: 15px;
      //line-height: 1.5em;
      margin-top: 12px;
      color: $color-text;
      p{ margin:0;}
    }
  }
  
  &.exhibition{
    .post-info{
      .exhibition-meta{
        //font-size: 14px;
        //line-height: 1.5em;
        margin-top: 10px;
        margin-bottom: 0;
        letter-spacing: 0.4pt;
      }
    }
  }
}

.post.col-xs-6{
   @media (max-width: 600px){
      width: 100%;
    }
}


/**
 * #News
 */

#news{
  .article-content{
    padding-top: 0;
  }
  .list-news{
    margin-bottom: 60px;
    .post-info{
      border: 1px solid #f6f6f6;
    }
  }
}
.news-category{
    border-bottom: 1px solid #e8e8e8;
    margin: 40px 0 90px;
    padding-bottom: 40px;
    text-align: center;
    a{
      color: $color-primary;
      font-size: 13px;
      letter-spacing: 0.8pt;
      padding: 6px 15px;
      text-transform: uppercase;
      &.active{
        color: $color-primary-light;
      }
    }
}

/**
 * #News detail
 */

#news-detail{
  .page-header.transparent{
    padding-top: 60px;
  }
  #breadcrumbs{
    margin-bottom: 60px;
  }
  .article-content{
    padding-top: 0;
  }
  .meta{
    text-align: center;
    margin-bottom: 15px;
  }
  h1{
    //margin: 0 0 30px 0;
    text-align: center;
    margin-bottom: 60px;
  }
.exhibition-meta{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;  
    display: -webkit-box;  
    display: -ms-flexbox;  
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 20px 0;
    margin-bottom: 60px;
    line-height: 1.3em;
    li{
      padding: 10px;
    }
  }
  .post-entry{
    font-size: 21px;
    font-weight: 300;
    line-height: 1.55em;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    
    @media (min-width: 992px){
      font-size: 24px;
      line-height: 1.6em;
    }
  }
  .post-img{
    margin-bottom: 60px;
    img{
      margin: 0 auto;
    }
  }
  .post-text{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    
    @media (min-width: 992px) {
      font-size: 17px;
      line-height: 1.6em;
    }
  }
  
  .post-downloads{
    max-width: 900px;
    margin: 60px auto 0;
    
    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }
}
