// -----------------------------------------------------------------------------
// This file contains all styles related to the grid of the site/application.
// -----------------------------------------------------------------------------

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  //max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  //width: 100%; /* 1 */
  @media (min-width: 1400px) {
    width: 1400px;
  }
}

.container-fluid {
  max-width: 1500px;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 992px) {
    padding-left: 70px;
    padding-right: 70px;
  }
}

/**
 * Content with sidebar
 */

.article-wrap {
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

#trabaja {
  .article-wrap {
    padding-bottom: 50px;
  }
}

.article-wrap {
  .sidebar {
    background-color: #fff;
    z-index: 1;
    padding-top: 4em;
    padding-bottom: 4em;

    @media (min-width: 992px) {
      padding-top: 6em;
      padding-bottom: 6em;

      &:before {
        background-color: #fff;
        content: "";
        display: block;
        height: 99999px;
        position: absolute;
        top: 0px;
        width: 3000px;
        z-index: -10;
      }
    }
  }
  &.sidebar-right {
    @media (min-width: 992px) {
      .article-content.col-md-9 {
        padding-right: 6em;
      }
      .sidebar.col-md-3 {
        padding-left: 3em;
      }
    }
    @media (min-width: 1200px) {
      .article-content.col-lg-9 {
        padding-right: 6em;
      }
      .sidebar.col-lg-3 {
        padding-left: 3em;
      }
    }
    @media (max-width: 1199px) {
      .sidebar.col-lg-3 {
        &:after {
          background-color: #fff;
          content: "";
          display: block;
          height: 99999px;
          position: absolute;
          top: 0px;
          left: -200px;
          width: 3000px;
          z-index: -10;
        }
      }
    }
    @media (max-width: 991px) {
      .sidebar.col-mg-3 {
        &:after {
          background-color: #fff;
          content: "";
          display: block;
          height: 99999px;
          position: absolute;
          top: 0px;
          left: -200px;
          width: 3000px;
          z-index: -10;
        }
      }
    }
    .sidebar:before {
      border-left: 1px solid #eee;
      left: 0;
    }
  }

  &.sidebar-left {
    .container-fluid {
      margin-left: 0;
    }
    @media (min-width: 992px) {
      .article-content {
        padding-left: 6em;
      }
    }
    .sidebar:before {
      border-right: 1px solid #eee;
      right: 0;
    }
  }
}

.article-content {
  padding-top: 4em;
  padding-bottom: 4em;

  @media (min-width: 992px) {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .col-576-6 {
    width: 50%;
  }
}

.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
