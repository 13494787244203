// -----------------------------------------------------------------------------
// This file contains styles that are specific to the News page.
// -----------------------------------------------------------------------------

/**
 * Quienes somos
 */

#about{
  #about-intro{
    h3{
      text-transform: uppercase;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: 1pt;
      color: $color-primary-mid;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    h4{
      font-size: 16px;
    }
  }
}


/* -------------------------------- 

Horizontal timeline

-------------------------------- */

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}
.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}
.cd-horizontal-timeline .timeline {
  position: relative;
  height: 100px;
  width: 90%;
  //max-width: 800px;
  margin: 0 auto;
}
.cd-horizontal-timeline .events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}
.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
  background-image: -webkit-linear-gradient( left , #ffffff, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
  background-image: -webkit-linear-gradient( right , #ffffff, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
}
.cd-horizontal-timeline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  background: #dfdfdf;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $color-primary-light;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 16px;
  padding-bottom: 15px;
  color: $color-primary-mid;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: $color-primary-light;
  border-color: $color-primary-light;
}
.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}
.cd-horizontal-timeline .events a.selected::after {
  background-color: $color-primary-light;
  border-color: $color-primary-light;
}
.cd-horizontal-timeline .events a.older-event::after {
  border-color: $color-primary-light;
}
@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline {
    margin: 6em auto;
  }
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
  /* arrow icon */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(../images/icons/cd-arrow.svg) no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
  right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
  border-color: $color-primary-light;
}
.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0 5%;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}
/*
.cd-horizontal-timeline .events-content li > * {
  max-width: 800px;
  margin: 0 auto;
}
*/
.cd-horizontal-timeline .events-content h3 {
  font-weight: bold;
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
}
.cd-horizontal-timeline .events-content .date {
  display: block;
  margin: 10px auto;
  font-size: 24px;
  color: $color-primary-mid;
}

.cd-horizontal-timeline .events-content p {
  font-size: 17px;
  color: #959595;
}
.cd-horizontal-timeline .events-content .date, .cd-horizontal-timeline .events-content p {
  line-height: 1.6;
}
@media only screen and (min-width: 768px) {
  .cd-horizontal-timeline .events-content h3 {
   font-size: 64px;
  }
  .cd-horizontal-timeline .events-content .date {
    font-size: 28px;
  }
  .cd-horizontal-timeline .events-content p {
    font-size: 22px;
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}



/**
 * Grupo Nicolas Correa
 */

.gnc-group-header{
  border-bottom: 1px solid $color-primary;
  padding-bottom: 70px;
  img{
    margin: 0 auto;
    
    @media (min-width: 480px) {
      max-width: 433px;
    }
  }
}
.gnc-group-list{
  @media (min-width: 768px){
    .flex-wrap{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
        
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
      }
    }

  }
}
.gnc-group-subsidiary{
  position: relative;
  
  h3{
    text-align: center;
  }
  .link{
    border-bottom: 1px solid $color-primary-mid;
    padding-bottom: 20px; 
    text-align: center;
    display: block;
    font-size: 13px;
  }
  @media (min-width: 768px){
    padding-top: 60px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    &:before{
      content: "";
      display: block;
      height: 60px;
      width: 1px;
      background-color: $color-primary;
      position: absolute;
      left: 50%;
      top: 0;
    }
    p{
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
    }
    .link{
      border-top: 1px solid $color-primary-mid;
      border-bottom: none;
      padding-top: 20px; 
    }
  }
}



/**
 * Grupo - Filiales
 */

#group-subsidiaries-list{
  .flex-wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
      }

  }
  .subsidiary{
    border: 1px solid #ddd;
    padding: 50px;
  }
  img{
    margin-bottom: 30px;
    max-height: 110px;
    //max-height: 170px;
    //max-width: 300px;
    &.logo-correa{
      max-height: 80px;
      max-width: 100%;
    }
  }
}


/**
 * Politica y estrategia
 */




/**
 * Medioambiente
 */

#about-enviroment{
  a.certificate{
    display: inline-block;
    max-width: 100%; /* ie11*/
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }
}
.enviroment-objectives {
  margin-bottom: 40px;
  @media (min-width: 768px) {
    margin-bottom: 60px;
  }
}
.enviroment-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  li {
    display: flex;
  }
  .col {
    background-color: #fff;
    padding: 32px;
    border-radius: 6px;
    display: flex;
    box-shadow: 0 4px 20px rgba(22,28,45,.05);
    margin-bottom: 16px;
    width: 100%;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: rgba(0, 114, 180, .1);
    color: $color-primary-mid;
    font-size: 12px;
    width: 22px;
    height: 22px;
    margin-right: 14px;
  }
  span {
    color: $color-primary-mid;
    font-size: 20px;
  }
  @media (min-width: 768px) {
    margin-top: 32px;
  }
  @media (min-width: 1200px) {
    text-align: center;
    li {
      width: 20%;
    }
    .col {
      flex-wrap: wrap;
      flex-direction: column;
    }
    .icon {
      margin: 0 auto 16px;
      font-size: 18px;
      width: 38px;
      height: 38px;
    }
  }
}
.chart {
  margin-top: 32px;
  margin-bottom: 32px;
}



/**
 * I+D+I
 */

.rdi-icon{
  @media (max-width: 1199px) {
    margin-top: 40px;
    max-width: 320px;
  }
}


.rdi-projects-list{
  li{
    border-bottom: 1px solid #eee;
  }
  a{
    display: block;
    padding: 2em;
    transform: translateX(0);
    div{
      position: relative;
      @include transition(all 0.3s ease);
      &:before{
        background: $color-primary-mid;
        content:"";
        position: absolute;
        left: 0;
        top: 12px;
        width: 0;
        height: 1px;
        transition: left 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s, width 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s;
      }
    }
    @media (min-width: 992px) {
      padding: 3em 4em;
      
      @include on-event {
        background-color: #f6f6f6;
        div{
          transform: translateX(40px);
          &:before{
            left: -45px;
            width: 30px;
          }
        }
      }
    }
  }
  h3{
    @media (min-width: 992px) {
      font-size: 26px;
    }
  }
  p{
    margin-bottom: 12px;
    color: $color-text;
  }
  span.link{
   font-size: 13px;
   @include transition(all 0.3s ease);
  }
}


/**
 * I+D+I detalle
 */

.rdi-project-info{
  margin-bottom: 60px;
}
.rdi-project-img{
  margin-bottom: 40px;
}

.rdi-project-logos{
  h4{
    font-size: 15px;
    letter-spacing: 1pt;
    text-transform: uppercase;
    border-bottom: 1px solid $color-primary;
    margin-bottom: 25px;
    padding-bottom: 10px;
    text-align: center;
  }
  ul{
    margin-bottom: 60px;
  }
  li{
    text-align: center;
    //margin-bottom: 25px;
    margin-bottom: 5px;
    font-size: 15px;
    letter-spacing: 0.25pt;
    img{
      max-width: 191px;
      max-height: 100px;
      margin: 0 auto 25px;
    }
  }
}
