/**
 * Basic typography style for copy text
 */

body {
    font-family: $font-primary;
    font-size: 17px;
    color: $color-text;	
    line-height: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::-moz-selection{ 
  color: #fff;  
  background-color: $color-primary-mid; 
}
::selection{ 
  color: #fff; 
  background-color: $color-primary-mid;
} 
  

// Headings
// -------------------------

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $font-secondary;
  font-weight: bold;
	color: $color-primary;	
  text-rendering : optimizelegibility;
  a{
    text-decoration: none;
  }
}
@media (min-width: 992px) {
  h1, .h1 { font-size: 54px; }
}
@media (min-width: 1200px) {
  .page-header h1, .page-header .h1 { font-size: 64px; }
}

@media (max-width: 991px) {
  h1, .h1 { font-size: 36px; }
  h2, .h2 { font-size: 30px; }
  h3, .h3 { font-size: 24px; }
  h4, .h4 { font-size: 18px; }
  h5, .h5 { font-size: 16px;; }
}
@media (max-width: 600px) {
  h1, .h1 { font-size: 30px; }
  h2, .h2 { font-size: 26px; }
  h3, .h3 { font-size: 22px; }
  h4, .h4 { font-size: 18px; }
  h5, .h5 { font-size: 16px;; }
}


// Body text
// -------------------------

p{
  margin-bottom: 20px;
}
p.lead, div.lead{
  line-height: 1.45em;
  color: $color-primary-mid;
}

// Lists
// -------------------------


.text{
  h1, h2{
     margin-bottom: 20px;
     margin-top: 40px;
  }
  h3{
     margin-bottom: 16px;
     margin-top: 30px;
  }

  ul{
    margin-bottom: 20px;
    li{
      position: relative;
      padding-left: 16px;
      margin-bottom: 10px;
      
      &:before{
        content:"\f111";
        font-size: 5px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        font-family: $font-awesome;
        color: $color-primary-mid;
      }
      ul{
        //padding-left: 25px;
        margin-top: 10px;
        margin-bottom: 20px;
        //font-size: 15px;
        
        li:before {
          content: "-";
          font-size: inherit;
           
        }
      }
    
    }
  }
  ol{
    margin-bottom: 20px;
    padding-left: 20px;
    li{
       list-style-type: decimal;
    }
  }
  li{
      margin-bottom: 5px;
  }
}
