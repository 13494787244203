// -----------------------------------------------------------------------------
// This file contains styles that are specific to the contact page.
// -----------------------------------------------------------------------------

.contact-info{
  text-align: center;
  padding: 20px;
  
  @media (min-width: 768px){
    padding: 40px 30px 50px;
  }
  
  li{
    font-size: 28px;
    padding: 10px 30px;
    
    @media (min-width: 768px){
      display: inline-block;
      font-size: 34px;
      border-right: 1px solid $color-primary;
      &:last-child{
        border-right: none;
      }
    }
  }
}

.contact-map-wrap{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }
}



#contact-map{
  position: relative;
  
  .gmap-container{
    width: 100%;
    @media (min-width: 992px){
        width: calc(100% - 430px);
     }
  }
  .embed-responsive-16by9{
    @media (min-width: 1400px){
      padding-bottom: 34%;
    }
  }
  
  .contact-address{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 2em 3em;
    
    @media (min-width: 992px){
        width: 430px;
     }
    
    h5{
      margin-top: 0;
      font-size: 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.39);
      padding-bottom: 18px;
      margin-bottom: 30px;
    }
  }
}