// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header{
  position: relative;
  z-index: 1000;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15);
}
.logo-color{
  fill: $color-azul;
}
/**
 * #searchbox
 */

#searchbox {
  background-color: $color-grey;
  height: 0;
  opacity: 0;
  transition: all 400ms ease-in-out 0s;
  visibility: hidden;
  width: 100%;
}
#searchbox .form-group{
  margin: 0;
  padding: 12px 10%;
  position: relative;

  &:before {
    content: "\f002";
    display: inline-block;
    font-family: $font-awesome;
    font-size: 26px;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: -16px;
    
    @media (min-width: 400px) {
      margin-left: -40px;
    }
    @media (min-width: 992px){
      margin-left: -50px;
    }
  }
}
#searchbox .form-control {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  height: 56px;
  line-height: 58px;
  padding: 0 20px;
  
  @media (min-width: 400px) {
    font-size: 34px;
    font-weight: 600;
  }

  &::-moz-placeholder {
    color: rgba(255, 255, 255, 0.2);
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: 700;
    font-size: 26px;
    @media (min-width: 400px) {
      font-size: 34px;
    }
  }
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.2);
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: 700;
    font-size: 26px;
    @media (min-width: 400px) {
      font-size: 34px;
    }
  }
 &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.2);
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: 700;
    font-size: 26px;
    @media (min-width: 400px) {
      font-size: 34px;
    }
  }
 &::placeholder {
    color: rgba(255, 255, 255, 0.2);
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: 700;
    font-size: 26px;
    @media (min-width: 400px) {
      font-size: 34px;
    }
  }
  
}
#searchbox .close-btn{
  background-color: transparent;
  background-image: url("../images/icons/close-icon.png");
  background-repeat: no-repeat;
  background-size: 20px auto;
  border: 0 none;
  height: 20px;
  width: 20px;
  right: 21px;
  top: 30px;
  opacity: 1;
  position: absolute;
  
  @media (min-width: 400px) {
    right: 26px;
  }
  @media (min-width: 992px){
    right: 30px;
  }
}

#searchbox.searchbox-open{
  height: 80px;
  opacity: 1;
  visibility: visible;
}


/**
 * Show and Hide #desktop-nav and #mobile-nav
 */

@media (min-width: 992px){
  #desktop-nav{
    display:block;
  }
  #mobile-nav{
    display:none;
  }
}

@media (max-width: 991px) {
  #desktop-nav{
    display: none;
  }
  #mobile-nav{
    display: block;
  }
}


// #mobile-nav

#mobile-nav{
  position: relative;
  border-bottom: 1px solid #eee;
}

// Menu Mobile

.menu-mobile{
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  
  @media (min-width: 400px) {
    height: 70px;
  }
  
  .brand{
    line-height: 0;
    margin-left: 20px;
    svg{
      width: 160px;
    }
    @media (min-width: 400px) {
      margin-left: 30px;
      svg{
        width: 185px;
      }
    }
  }
  .btn-search{
    height: 60px;
    width: 60px;
    line-height: 60px;
    position: absolute;
    top: 0;
    right: 60px;
    background-color: #fbfbfb;
    border-left: 1px solid #eee;
    color: $color-primary;
    text-align: center;
    
    @media (min-width: 400px) {
      height: 70px;
      width: 70px;
      line-height: 70px;
      right: 70px;
    }
  }
}

/* .nav-trigger */

.btn-toogle {
  /* menu icon - visible on small screens only */
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 60px;
  background-color: #eee;
  /* replace text with icon */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
  
  
  @media (min-width: 400px) {
    height: 70px;
    width: 70px;
  }

}
.btn-toogle span, 
.btn-toogle span::after, 
.btn-toogle span::before {
  /* these are the 3 lines of the menu icon */
  position: absolute;
  background-color: $color-primary;
  height: 2px;
  width: 26px;
}

.btn-toogle span {
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
}
.btn-toogle span::after, .btn-toogle span::before {
  content: '';
  left: 0;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}
.btn-toogle.collapsed span::before {
  -webkit-transform: translateY(-9px);
      -ms-transform: translateY(-9px);
          transform: translateY(-9px);
}
.btn-toogle.collapsed span::after {
  -webkit-transform: translateY(9px);
      -ms-transform: translateY(9px);
          transform: translateY(9px);
}
.btn-toogle span {
  background-color: transparent;
}
.btn-toogle.collapsed span {
  background-color: $color-primary;
}

.btn-toogle span::before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.btn-toogle span::after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);

}

// Navbar collapse (#mobile-nav)

 .navbar-collapse {
  padding-right: 0px;
  padding-left:  0px;
  border-top: 0;
  box-shadow: none;

  &.in {
    overflow-y: auto;
  }
}

// Reset Bootstraps navbar collapse breakpoint
// This content is collapsed when <992 instead of <768px

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    } 
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
}

// Mobile nav

#mobile-nav .navbar-collapse{
  background-color: #eee;
  li a{
    display: block;
  }
  .mobile-nav-secondary{
    a{   
      display: inline-block;
    }
  }
  .mobile-nav-users a{
    display: inline-block;
  }
  
}
.mobile-nav-main{
  margin-bottom: 20px;
  li.has-submenu{
    ul{
      background-color: #f9f9f9;
      font-size: 15px;
      a{
        border-bottom: 1px solid #e6e4e4;
        color: $color-primary-mid;
        font-weight: normal;
      }
    }
  }
  a{
    border-bottom:1px solid #ddd;
    padding: 15px 20px;
    color: $color-primary;
    font-weight: 600;
  }
}

#mobile-nav .nav-lang{
  margin: 20px 0;
  padding-left: 15px;
  font-size: 13px;
  a{
    color: $color-primary;
    padding: 5px;
    &.active{
      color: $color-primary-light;
    }
  }
}
#mobile-nav .mobile-nav-users{
  margin: 20px 0;
  padding-left: 20px;
  font-size: 15px;
  > li {
      display: inline-block;
      color: $color-primary;
      + li:before {
        content: "- "; 
        padding: 0 10px;
        color: #999;
      }
    }
  a{
    display: inline-block;
  }
    .user-name{
      color: $color-primary-mid;
      font-weight: 600;
      i{
        font-size: 14px;
        margin-right: 3px;
      }
    }
    .user-logout{
      font-size: 14px;
      color: $color-text;
    }
}
.mobile-nav-secondary{
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  font-size: 15px;
  li{
     padding: 10px 20px;
  }
  a{   
    color: $color-text;
  }
}

#mobile-nav .nav-social{
  margin-bottom: 40px;
  padding-left: 20px;
  font-size: 16px;
  a{
    padding: 5px;
  }
}

// Dropdown arrow

.link-dropdown{
  position:relative;

  .arrow{
    position: absolute;
    color: #fff;
    right: 0;
    top: 0;
    height: 100%;
    width: 70px;
    text-align: center;
    cursor: pointer;

    &:before{
      position: absolute;
      content: "\f107";
      font-family: $font-awesome;
      color: #000;
      font-size: 20px;
      line-height: 18px;
      right: 0;
      top: 50%;
      left: 0;
      right: 0;
      margin: -8px auto 0;
      transform: rotate(0deg);
      @include transition(all 0.3s ease 0s);
    }
    &.collapsed:before{
      transform: rotate(-90deg);
      @include transition(all 0.3s ease 0s);
    }
  }
}

// #desktop-nav

/**
 * #top-header
 */

#top-header{
  font-weight: 500;
  //text-transform: uppercase;
  border-bottom: 1px solid rgba(176, 176, 176, 0.16);
  height: 42px;
  line-height: 40px;
  text-align: right;

  a{
    color: $color-primary;
    @include on-event {
      color: $color-primary-mid;
    }
  }

  .nav-social{
    display: inline-block;
    margin-right: -4px;
    padding: 0 20px;
    a{ padding: 5px; }
  }
  .nav-users{
    display: inline-block;
    margin-right: -4px;
    border-left: 1px solid #f0f1f2;
    padding: 0 30px;
    font-size: 15px;
    > li {
      display: inline-block;
      color: $color-primary;
      + li:before {
        content: "- "; 
        padding: 0 10px;
        color: #999;
      }
    }
    .user-name{
      color: $color-primary-mid;
      font-weight: 600;
      i{
        font-size: 14px;
        margin-right: 3px;
      }
    }
    .user-logout{
      font-size: 14px;
      color: $color-text;
    }
    
  }
  .nav-top-links{
    display: inline-block;
    margin-right: -4px;
    border-left: 1px solid #f0f1f2;
    padding: 0 20px;
    font-size: 15px;
    a { 
		padding: 5px;
		span {
			font-weight: 800;
		}
	  }
  }
  .nav-lang{
    display: inline-block;
    margin-right: -4px;
    border-left: 1px solid #f0f1f2;
    text-transform: uppercase;
    a{
      padding: 0 30px;
      font-size: 14px;
    }
    .dropdown-menu {
      min-width: 91px;
      li a{
        border-bottom: 1px solid rgba(176, 176, 176, 0.16);
        border-top: none;
        padding-top: 6px;
        padding-bottom: 6px;
        color: $color-grey;
        font-size: 13px;
      }
    }
  }
  #search{
    display: inline-block;
    border-left: 1px solid #f0f1f2;
    a{
      display: block;
      padding: 0 30px;
    }
  }
}



/**
 * #main-header
 */

#main-header.fixed{
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 2px 6px rgba(10, 10, 10, 0.06);
  background-color: #fff;
  
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

#main-header{
  border-bottom: 1px solid #eee;
  height: 100px;
  
  .flex-wrapper{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 0 30px;
      
      height: 100%; /* ie11 bug */

      @media (min-width: 1400px){
        padding: 0 60px;
      }
    }
  /*
  .brand {
		
    svg{
      width: 155px;
      display: block;
    }
     @media (min-width: 1200px){
     svg{
        width: 180px;
      }
    }
     @media (min-width: 1400px){
     svg{
        width: 210px;
      }
    }
		
  }
	*/
  .btn{
     @media (max-width: 1199px){
       padding-right: 20px;
       padding-left: 20px;
    }
  }
}


/* .main-nav */

.cd-morph-dropdown::before {
  content: 'desktop';
  display: none;
}

.cd-morph-dropdown {
  position: relative;
  
  .main-nav {
    display: block;
    > ul > li {
      display: inline-block;
    }
    > ul > li > a {
      display: block;
      padding: 0 0.7em;
      height: 100px;
      line-height: 100px;
      color: $color-primary;
      font-weight: 600;
      font-size: 16px;
      transition: opacity .2s;
      
      @media (min-width: 1200px){
        font-size: 17px;
        padding: 0 1em;
      }
      @media (min-width: 1400px){
        font-size: 18px;
        padding: 0 1.3em;
      }

    }
  }

  &.is-dropdown-visible .main-nav > ul > li > a {
    /* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
    opacity: .6;
  }

  &.is-dropdown-visible .main-nav > ul > li.active > a {
    opacity: 1;
  }

  .morph-dropdown-wrapper {
    position: absolute;
    left: 0;
    /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
    display: block;
    top: 100px;
    width: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    /* Force Hardware acceleration */
    transform: translateZ(0);
    will-change: transform;
    transform: translateY(20px);
    transition: transform .3s;
  }

  &.is-dropdown-visible .morph-dropdown-wrapper {
    transform: translateY(0);
  }

  .dropdown-list {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      transform: translateZ(0);
      will-change: transform, width, height;
      transition: visibility .3s;
      box-shadow: 0 10px 20px rgba(#000, .08);

      .no-csstransitions & {
        display: none;
      }

      &::before {
        /* dropdown top triangle */
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        right: auto;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 0;
        width: 0;
        border: 8px solid transparent;
        border-bottom-color: #f4f4f4;
        opacity: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
      }

      > ul {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }

      > ul > li {
        margin-bottom: 3.3em;
      }
  }

  &.is-dropdown-visible .dropdown-list {
    visibility: visible;
    transition: transform .3s, width .3s, height .3s;

    &::before {
      opacity: 1;
    }
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transition: opacity .3s, visibility .3s;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &.move-left .content {
      transform: translateX(-100px);
    }

    &.move-right .content {
      transform: translateX(100px);
    }
  }

  .content {
      width: 350px;
      padding: 2.2em 1.8em;
      transition: transform .3s;
      text-align: left;
      overflow: hidden;
      font-size: 17px;

      li {
        margin-bottom: 1em;
        padding-left: 20px;
      }
      .list-links li a{
        display: block;
        position: relative;
        transform: translateX(0);
        color: $color-primary;
        &:before{
          background: $color-primary-mid;
          content:"";
          position: absolute;
          left: 0;
          top: 10px;
          width: 0;
          height: 1px;
          transition: left 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s, width 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        @include on-event {
          transform: translateX(14px);
          &:before{
            left: -14px;
            width: 8px;
          }
        }
      }
  }

  .gallery .content {
      /* you need to set a width for the .content elements because they have a position absolute */
      width: 510px;
      li {
        width: 48%;
        float: left;
        margin-right: 4%;
        margin-top: 0;
        margin-bottom: 1.4em;
        &:after {
          clear: both;
          content: "";
          display: block;
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
      li a{
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;      
      }
      img{
        border-radius: 50px;
        margin-right: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-negative: 0;
            flex-shrink: 0;
      }
      span{
        line-height: 1.3em;
        font-weight: 600;
      }
    
    }
  .gallery2 .content {
    //width: 610px;
    width: 800px;
    li {
        width: 30.666%;
        margin-right: 4%;
      
        &:nth-of-type(2n) {
         margin-right: 4%;
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    img{
        border-radius: 0;
        height: 52px;
        width: 52px;
      }
  }

  .bg-layer {
    /* morph dropdown background */
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    background: #f4f4f4;
    opacity: 0;
    transition: opacity .3s;
    transform-origin: top left;
    transform: translateZ(0);
    will-change: transform;
    backface-visibility: hidden;
  }

  &.is-dropdown-visible .bg-layer {
    opacity: 1;
    transition: transform .3s, opacity .3s;
  }

}
