// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Distributors page.
// -----------------------------------------------------------------------------


#distributors-login{
  .page-section{
    padding-top: 60px;
  }
}

.login-distributors{
  max-width: 400px;
  margin: 0 auto;
}

#distributors{
  .list-items{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }

    .box-item{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    .item-info{
      -webkit-box-flex: 1;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
    }
  }
}

#distributors{
  .box-item{
    figure{
      background: #fff;
      .img-container{
        padding: 24px;
      }
    }
    .item-info{
      padding: 18px 24px;
      
      h3{
        text-transform: none;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}

.btn-sales{
  margin-bottom: 30px;
}



#forgot-password{
  .page-section{
    padding-top: 60px;
  }
}