// -----------------------------------------------------------------------------
// This file contains styles that are specific to the GNC en el mundo page.
// -----------------------------------------------------------------------------

/**
 * GNC Mundo
 */

#map-filters{
  background-color: $color-primary;
}

#gnc-world{
  position: relative;
  //height: 80vh;
  height: 100%;
  overflow: hidden;
}

#map_canvas{
  //height: 100%;
  height: calc(100vh - 142px);
  
  @media (min-width: 992px){
     height: calc(100vh - 142px); //altura del header: 142px
  }
  
}

#pane{
  position: absolute;
  top: 60px;
  left: 60px;
  //width: 300px;

  z-index: 10;
  //height: 100%;
  height: 80%;

  .nav-tabs{
    border: none;
    >li{
      margin-bottom: 0;
    }
  }
  
  .nav-tabs > li > a{
    border-radius: 0;
    border: none;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.8pt;
    padding: 14px 30px;
    text-transform: uppercase;
    margin-right: 0;
    
    &.venta-link{
      background-color: rgba($color-primary, 0.84);
    }
     &.postventa-link{
      background-color: rgba($color-primary-mid, 0.84);
    }
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover{
    border-radius: 0;
    border: none;
    
  }
  .tab-content{
    //height: 80%;
    //overflow: auto;
    height: 100%;
  }
  .tab-content .tab-pane{
    //padding: 30px;
    font-size: 15px;
    //box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
    height: 100%;
  }

  .flex-wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    //overflow: hidden;
  }
  
}

.list-countries{
  overflow: auto;
  height: 100%;
  z-index: 2;
  //width: 300px;
  width: 100%;
  min-width: 300px;
  color: #fff;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  a{
    color: #fff;
    display: block;
    padding: 5px 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.07);
  }
  @include when-inside('#venta'){
    background: rgba($color-primary, 0.84);
     a.active{
      background-color: rgba($color-primary, 0.84);
    }
  }
  @include when-inside('#postventa'){
    background: rgba($color-primary-mid, 0.84);
     a.active{
      background-color: rgba($color-primary-mid, 0.84);
    }
  }
}
.list-addresses{
  background-color: #fff;
  color: $color-text;
  padding: 30px;
  width: 260px;
  opacity: 0;
  transition: all 400ms ease-in-out 0s;
  visibility: hidden;
  //transform: translateX(-100%);
  transform: translateX(0);
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  z-index: 1;
  font-size: 14px;
  
  position: absolute;
  //height: 80%;
  height: 100%;
  overflow: auto;
  right: 0;

  h4{
    font-size: 15px;
  }
  &.addresses-open {
    opacity: 1;
    visibility: visible;
    transform: translateX(100%);
    //transform: translateX(0);
  }
}
.close-adr{
  background-color: transparent;
  background-image: url("../images/icons/close-icon-color.png");
  background-repeat: no-repeat;
  background-size: 12px auto;
  border: 0 none;
  height: 12px;
  opacity: 1;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 12px;
}


.select-country{
  display: block;
  padding: 14px 30px;
  color: #fff;
  position: relative;
  
  @include on-event {
    color: #fff;
	}
  
  .arrow{
    position: absolute;
    color: #fff;
    right: 0;
    top: 0;
    height: 100%;
    width: 70px;
    text-align: center;
    cursor: pointer;

    &:before{
      position: absolute;
      content: "\f107";
      font-family: $font-awesome;
      color: #fff;
      font-size: 20px;
      line-height: 18px;
      right: 0;
      top: 50%;
      left: 0;
      right: 0;
      margin: -8px auto 0;
      transform: rotate(0deg);
      @include transition(all 0.3s ease 0s);
    }
    &.collapsed:before{
      transform: rotate(-90deg);
      @include transition(all 0.3s ease 0s);
    }
  }
  
}
@media (min-width: 769px){
  .select-country{
    display: none;
  }
  .list-countries.collapse{
    display: block!important;
  }
}

@media (max-width: 768px){
  #pane{
    position: relative;
    height: auto;
    top: 0;
    left: 0;
    
    .nav-tabs li{
      width: 50%;
      margin: 0;
      text-align: center;
      a{
        margin: 0;
      }
    }
    .tab-content{
      height: auto;
      overflow: hidden;
    }
    .tab-pane{
      color: #fff;
      &#venta{
        background: rgba($color-primary, 0.84);
      }
      &#postventa{
        background: rgba($color-primary-mid, 0.84);
      }    
    }
  }
  .list-countries{
    //display: none;
    width: 100%;
  }
  .list-addresses{
    display: none;
  }
 
}


