// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Site map page.
// -----------------------------------------------------------------------------



.list-site-map li {
	margin-bottom: 10px;
}
.list-site-map li a{
	font-weight: bold;
	color: $color-primary;
  
  @include on-event {
    color: $color-primary-light;
	}
}
.list-site-map li ul {
	padding-left: 25px;
	margin-top: 10px;
	margin-bottom: 20px;
  font-size: 15px;
}
.list-site-map li ul li {
	margin-bottom: 10px;
	position: relative;
}
.list-site-map li ul li a{
	text-transform: none;
	font-weight: normal;
}
.list-site-map li:before {
    content: "";
}
.list-site-map li ul li:before {
   content: "-";
	 position: absolute;
	 left: -15px;
	 top: 0;
}
