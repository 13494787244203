// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

/**
 * Slider Intro
 */

#slider-home.carousel .item {
	 cursor: grab !important;
}

#slider-home .slider-img{
	position: relative;
	z-index: 1;
	height: 72vh;
}

#slider-home .carousel-caption {
	height: 100%;
  left: 15%;
  right: 15%;
	padding: 30px 0;
	bottom: 0;
  text-align: left;
  max-width: 900px;
  
  .container-fluid{
    padding: 0;
  }
}

#slider-home .carousel-caption h2 {
	font-weight:bold;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
	font-size: 24px;
	margin: 0 0 15px 0;
	color: #FFF;
	position: relative;
  text-shadow: none;
  
  @media (min-width: 660px) {
    font-size: 48px;
     margin-bottom: 24px;
  }
  @media (min-width: 992px) {
    font-size: 52px;
    letter-spacing: -0.05px;
    margin-bottom: 38px;  
  }
  @media (min-width: 1200px) {
    font-size: 60px;
    margin-bottom: 40px;  
  }
  @media (min-width: 1400px) {
    font-size: 68px;
  }
}

#slider-home .carousel-control{
  text-shadow: none;
  
  &.left, &.right{
    //background-image: none;
  }
  
  // Toggles
  &.left i, &.right i {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
    font-size: 30px;
  }
  &.left i {
    left: 20%;
    //margin-left: -10px;
    margin-left: 0;
  }
  &.right i {
    right: 20%;
    //margin-right: -10px;
    margin-right: 0;
  }

  @media (min-width: 758px) {
     &.left i, &.right i{
      font-size: 48px;
    }
  }
}

.carousel-fade .carousel-inner .item {
	opacity: 0;
	transition-property: opacity;
	&:after{
		background: rgba(0, 0, 0, 0) linear-gradient(transparent, rgba(0, 0, 0, 0.6)) repeat scroll 0 0;
		bottom: 0;
		content: "";
		height: 50%;
		left: 0;
		position: absolute;
		width: 100%;
		z-index:2;
	}
}

.carousel-fade .carousel-inner .active {
	opacity: 1;
}
.carousel-fade .carousel-inner .active.left, 
.carousel-fade .carousel-inner .active.right {
	left: 0;
	opacity: 0;
	z-index: 1;
}
.carousel-fade .carousel-inner .next.left, 
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}
.carousel-fade .carousel-control {
	z-index: 2;
}

#slider-home{
  .carousel-indicators li{
    border-radius: 0;
    border: none;
    width: 30px;
    height: 4px;
    margin: 6px;
    background-color: #fff;
    
    &.active{
      background-color: $color-primary-light;
    }
  }
}



/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-fade .carousel-inner > .item.next,  
  .carousel-fade .carousel-inner > .item.active.right {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	.carousel-fade .carousel-inner > .item.prev,  
  .carousel-fade .carousel-inner > .item.active.left {
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	.carousel-fade .carousel-inner > .item.next.left,  
  .carousel-fade .carousel-inner > .item.prev.right,  
  .carousel-fade .carousel-inner > .item.active {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

/**
 * Featured-home
 */

#featured-home{
  h1{
    text-align: center;
    margin-top: 0;
    
    @media (min-width: 992px) {
      font-size: 44px;
      margin-bottom: 60px;
    }
  }
  .fancy-title{
    color: $color-primary-mid;
    &:before{
      width: 60%;
    }  
  } 
}

.featured-list{
  li{
    text-align: center;
    a{
      position: relative;
      display: block;
      padding: 10px 10px 25px 10px;
      
      @media (min-width: 1200px){
        padding: 40px; 
      }
      &:after{
        content: "";
        position: absolute;
        margin: 0 auto;
        left:0;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 18px;
        border-color: transparent transparent #eee transparent;
        opacity: 1;

      }
      &.collapsed:after{
        opacity: 0;
      }
      &.collapsed.active:after{
        opacity: 1;
      }
    }
    h3{
      margin-bottom: 0;
    }
    @media (min-width: 768px){
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
    }
  }
  @media (min-width: 768px){
      display:-webkit-box;
      display:-ms-flexbox;
      display:flex;
  }
  @media (max-width: 767px){
      display: none;
  }
}

.featured-collapse{
  
  @media (max-width: 767px){
    .collapse{
      display: block;
      margin-bottom: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-primary;
      &:last-child{
        border-bottom: none;
      }
    }
    .featured-img, .featured-text, .featured-video{
      margin-bottom: 30px;
      text-align: center;
    }
    .featured-img img{
      margin: 0 auto;
      max-height: 180px;
    }
  }
  
}
.featured-info{
  
  @media (min-width: 768px){
    border-radius: 4px;
    background-color: #eee;
    padding: 4em 3em;
    
    p{
      font-size: 20px;
      line-height: 1.6em;
    }
  }
  
  h3{
    margin-top: 10px;
  }
  
  .featured-img{
    @media (min-width: 768px) and (max-width: 1199px){
      display: none;
    }
  }
}

/**
 * About-home
 */


#about-home{
  .heading-line{
    padding-bottom: 30px;
    &:before{
      border-bottom: 1px solid rgba(255,255,255,0.4);
    }
  }
  .text{
    font-size: 18px;
  }
  .video{
    margin-top: 30px;
  }
   
}

/**
 * Efecto hover
 */
@media (min-width: 768px) {
  .collapsed.active {

  }
  .collapse.active {
    display: block;
  }
}







