// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  font-size: 100%;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -webkit-tap-highlight-color: transparent;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */

a {
  color: $color-primary-mid;
  text-decoration: none;

  @include on-event {
    color: $color-primary-light;
    text-decoration: none;
    outline: 0;
  }
  @include transition(all 0.3s ease);
}

a.link,
span.link {
  letter-spacing: 2.2px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  i {
    font-size: 19px;
    font-weight: 700;
    position: relative;
    top: 1px;
    margin-left: 8px;
    @include transition(all 0.3s ease);
  }
  @include on-event {
    i {
      margin-left: 12px;
    }
  }
}

/**
 * Reset styles for lists
 */

ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/**
 * Images
 */

svg#logo {
  .logo-color {
    fill: $color-primary;
  }
  .logo-white {
    fill: #fff;
  }
}

/* .rollover */

.rollover {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 9;
  transition: all 0.6s ease 0s;

  &:hover img {
    transform: scale(1.05);
  }
}
.rollover .hover {
  background: rgba($color-primary, 0.55) none repeat scroll 0 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.rollover:hover .hover {
  opacity: 1;
}
.line-wrap {
  height: 40px;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  width: 40px;
}
.line-wrap .line1 {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 2px;
  margin-top: 50%;
  transform: translateY(1px);
  width: 0;
}
.line-wrap .line2 {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 0;
  margin-left: 50%;
  margin-top: -50%;
  transform: translateX(-1px);
  width: 2px;
}
.rollover {
  #download-icon {
    height: 40px;
    left: 50%;
    margin-left: -26px;
    margin-top: -28px;
    position: absolute;
    top: 50%;
    width: 40px;
    fill: #fff;
  }
}

/* center images */

.force-height {
  padding-top: 80%;
}

.img-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.centerer {
  font: 0px/0 a;
  text-align: center;
  &:before {
    content: " ";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.centered {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Background colors
 */

//Background grey

.bg-grey {
  background-color: #f6f6f6;
}

//Background white

.bg-white {
  background-color: #fff;
}

//Background blue dark

.bg-blue {
  @include bg-custom($color-primary, #ddd);
  a {
    color: #fff;
    @include on-event {
      color: #ddd;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #fff;
  }
}
a.bg-blue {
  @include bg-custom($color-primary, #ddd);
  @include on-event {
    color: #ddd;
    background: $color-primary;
  }
}

/**
 * Page Section
 */

.page-section {
  padding: 60px 0;

  @media (min-width: 768px) {
    padding: 90px 0;
  }
  @media (min-width: 992px) {
    padding: 120px 0;
  }
  @media (min-width: 1200px) {
    padding: 140px 0;
  }
  /*
  @media (min-width: 1400px) {
    padding: 160px 0;
  }
  */
  .fancy-title {
    margin-top: 0;
    background-color: #fff;
  }
}

.section-header {
  position: relative;
  margin-bottom: 40px;
  background-color:  #fff;

  @media (min-width: 992px) {
    margin-bottom: 80px;
  }

  .title {
    font-weight: 700;
    margin-top: 0;
    //margin-bottom: 40px;
    text-transform: uppercase;
  }

  a.link {
    //background-color: #f6f6f6;
    line-height: normal;
    display: inline-block;
    margin: 0 auto;
    margin-top: -16px;
    padding: 5px 20px;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    /*@include when-inside('.bg-white'){
      background-color: #fff;
    }*/
  }
  @media (min-width: 768px) {
    position: relative;
  }
}

.section-title {
  margin-bottom: 40px;
  margin-top: 0;

  @media (min-width: 768px) {
    margin-bottom: 60px;
  }
}

.fancy-title {
  position: relative;
  font-weight: 700;
  margin-top: 0;
  //margin-bottom: 60px;
  z-index: 1;
  text-transform: uppercase;

  &:before {
    background: $color-primary-mid;
    opacity: 0.5;
    content: "";
    display: block;
    height: 1px;
    margin-right: 0;
    vertical-align: middle;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    z-index: -1;
  }
  span {
    background-color: #fff;
    display: inline-block;
    padding: 5px 30px 5px 0;

    @include when-inside(".text-center") {
      padding-left: 30px;
    }
    @include when-inside(".bg-white") {
      background-color: #fff;
    }
    @include when-inside(".bg-blue") {
      background-color: $color-primary;
    }
  }
  &.text-center {
    span {
      padding-left: 30px;
    }
  }
}

.section-header.-flex {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  .fancy-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 2;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
  }
  a.link {
    padding: 15px 0 0 20px;
    background-color: transparent;
    margin: 0;
    top: auto;
    position: relative;
  }
  @media (max-width: 600px) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    a.link {
      padding-left: 0;
    }
  }
}
#page-content {
  h1 {
    margin-bottom: 60px;
  }
}
