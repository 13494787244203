// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Milling heads page.
// -----------------------------------------------------------------------------

.heading-line {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 30px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 9999px;
    border-bottom: 1px solid $color-primary-mid;
  }
}

#milling-heads {
  .flex-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    &.row {
      &:before,
      &:after {
        content: normal; // IE doesn't support `initial`
      }
    }
  }
}

.milling-tech {
  &.page-section {
    padding-bottom: 0;
  }
  h2 {
    margin-bottom: 40px;
    text-transform: uppercase;
  }
}

.milling-tech-features {
  padding: 60px 0;
  margin-top: 60px;
  font-size: 16px;
  line-height: 1.5em;

  h3 {
    font-size: 19px;
    text-transform: uppercase;
  }
  ul {
    padding-left: 1rem;
    li {
      list-style: disc;
      margin-bottom: .5rem;
      &:before {
        color: #fff;
      }
    }
  }
}

#milling-range {
  h2 {
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}

.milling-range-items {
  margin-top: 60px;

  .item {
    margin-bottom: 30px;
  }

  a {
    &.item {
      color: $color-text;
    }
  }

  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    line-height: 1.5em;
  }

  @media (max-width: 400px) {
    .col-xs-6 {
      width: 100%;
    }
  }
}
