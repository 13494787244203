// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Machines page.
// -----------------------------------------------------------------------------

/**
 * Machines
 */




/**
 * Machines category
 */

.machine-category{
  margin-bottom: 40px;
  .fancy-title {
    a{
      color: $color-primary;
      @include on-event {
       color: $color-primary-mid;
     }
    }
  }
}
.list-machines{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    &.row{
    &:before, &:after{
      content: normal; // IE doesn't support `initial`
    }
  }
  
  .box-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .item-info{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
}



/**
 * Machines detail
 */

/* m-gallery */

.m-detail-section{
   margin-bottom: 60px;
  
  @media (min-width: 992px){
    margin-bottom: 90px;
  }
  .fancy-title{
    margin-bottom: 30px;
  }
}

.slider-machines{
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  margin-bottom: 40px;
}

.slider-nav .item{
  position: relative; 
  cursor: pointer;
  margin: 0 5px;
  opacity: 0.7;  
   &.slick-current{
     opacity: 1;
  }
   &:focus{
    outline: none;
  }
}

.play-icon{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 58, 94, 0.44);
    &:before{
      background: rgba(0, 0, 0, 0) url("../images/icons/play.min.svg") no-repeat scroll center center / 30% auto;
      border: 1px solid rgba(255, 255, 255, 0.44);
      border-radius: 50%;
      content: "";
      height: 48px;
      width: 48px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: -24px auto 0;
      transition: transform 0.3s ease 0s;
    }
}

.youku{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 58, 94, 0.44);
    &:before{
      background: rgba(0, 0, 0, 0) url("../images/icons/youku.png") no-repeat scroll center center / 30% auto;
      content: "";
      height: 256px;
      width: 256px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: -128px auto 0;
      transition: transform 0.3s ease 0s;
    }
}
/* m-icons */

.m-icons{
  .text-lead{
    margin-bottom: 40px;
    @media (min-width: 992px){
      font-size: 21px;
      font-weight: 300;
      line-height: 1.5em;
    }
  }
}

.machine-icons-list {
  position: relative;
  
  @media (min-width: 600px){
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }

  }
    
  li{
    padding: 0 10px;
    margin-bottom: 10px;
    
    @media (min-width: 600px){
      -ms-flex-preferred-size: 24%;
          flex-basis: 24%;
    }
    @media (min-width: 992px){
      -ms-flex-preferred-size: 20%;
          flex-basis: 20%;
    }
    @media (min-width: 1200px) and (max-width: 1400px){
      -ms-flex-preferred-size: 24%;
          flex-basis: 24%;
    }
    @media (min-width: 1401px){
      -ms-flex-preferred-size: 20%;
          flex-basis: 20%;
    }
    a{
      position: relative;
      display: block;
      padding-bottom: 25px;

      &:after{
        content: "";
        position: absolute;
        margin: 0 auto;
        left:0;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 14px 15px;
        border-color: transparent transparent #fff transparent;
        opacity: 1;
        z-index: 2000;
      }
      &.collapsed:after{
        opacity: 0;
      }
    }
    .picto-icon{
      @media (max-width: 599px){
        max-width: 180px;
        margin:0 auto;
      }
    }
  }
  
}

.icon-info-collapse{
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  
  @media (min-width: 600px){
    position: absolute;
    left: 0;
    margin-left: 10px;
    width: calc(100% - 20px);
  }
  
  .icon-info-wrap{
    padding: 20px;
  }
}

.slider-icon.slick-slider{
  //padding: 1rem;
}

.slider-icon .slick-slide{
   padding: 10px 30px;
  
  .icon-info-img img{
    margin: 0 auto;
  }

  @media (min-width: 600px) {
    
  }
}

.slider-icon .slick-prev::before, 
.slider-icon .slick-next::before {
    color:  $color-primary;
    font-family: $font-awesome;
    font-size: 30px;
    //font-weight: bold;
}
.slider-icon .slick-prev {
    z-index: 9998;
    left: 0;
    &:before{
      content: "\f104";
    }
}
.slider-icon .slick-next {
    z-index: 9998;
    right: 0;
    &:before {
      content: "\f105";
    }
}


/* m-technical-data */

.m-technical-data{
  .table-custom.table{
     tbody > tr > td{
      text-align: center;
    }
  }
}

/* m-milling-heads */

.cards-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }
  margin: 0 -15px 30px;
}

.card{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 1%;
          flex: 1 1 1%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  @media (min-width: 1200px) and (max-width: 1300px){
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  @media (max-width: 800px){
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}

.card{
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  margin: 15px;
  overflow: hidden;
  padding: 35px 35px 35px 155px;
  position: relative;
  transition-duration: 0.15s;
  transition-property: color, background-color, box-shadow, transform;
  z-index: 499; 
  h3{
    margin-top: 0;
    font-size: 20px;
  }
  p{
    margin-bottom: 0;
  }
  img{
    position: absolute;
    left: -30px;
    top:20px;
    width: 160px;
  }
  @media (max-width: 480px){
    padding: 25px 25px 25px 95px;
    font-size: 16px;
    line-height: 1.5em;
    
    img {
    width: 100px;
    top: 35px;
    }
  }
}

.m-milling-heads{
  .text-lead{
    font-size: 26px;
    line-height: 32px;
    color: $color-primary-mid;
  }
}

/* m-equipment */

.m-equipment{
  h3{
    color: $color-primary-mid;
    margin-bottom: 20px;
    letter-spacing: 0.5pt;
    font-size: 21px;
    text-transform: uppercase;
  }
}

/* m-equipment */

.m-applications{
  
  .box-item{
    .item-info{
      .category{
        display: block;
        line-height: normal;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  
}