// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@each $font in glyphicons-halflings-regular, font-awesome {
  @include font-face-opt($font, $font);
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on May 16, 2017 9:08 AM */

@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Regular.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Regular.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Regular.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Regular.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-It.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-It.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-It.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-It.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-It.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-It.svg#MyriadPro-It') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Semibold.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Semibold.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Semibold.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Semibold.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-SemiboldIt.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-SemiboldIt.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-SemiboldIt.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-SemiboldIt.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-SemiboldIt.svg#MyriadPro-SemiboldIt') format('svg');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Bold.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Bold.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Bold.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Bold.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-BoldIt.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldIt.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldIt.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldIt.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldIt.svg#MyriadPro-BoldIt') format('svg');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro Condensed';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Cond.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Cond.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Cond.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Cond.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-Cond.svg#MyriadPro-Cond') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro Condensed';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-CondIt.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-CondIt.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-CondIt.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-CondIt.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-CondIt.svg#MyriadPro-CondIt') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro Condensed';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-BoldCond.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCond.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCond.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCond.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCond.svg#MyriadPro-BoldCond') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro Condensed';
	src: url('../fonts/MyriadPro/hinted-MyriadPro-BoldCondIt.eot');
	src: url('../fonts/MyriadPro/hinted-MyriadPro-BoldCondIt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCondIt.woff2') format('woff2'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCondIt.woff') format('woff'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCondIt.ttf') format('truetype'),
		url('../fonts/MyriadPro/hinted-MyriadPro-BoldCondIt.svg#MyriadPro-BoldCondIt') format('svg');
	font-weight: bold;
	font-style: italic;
}







