// -----------------------------------------------------------------------------
// This file contains styles that are specific to the error page.
// -----------------------------------------------------------------------------


#error-404{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;  
  text-align: center;
  min-height: calc(100vh - 142px);
  background-image: url(../images/about/rdi-header.jpg);
}