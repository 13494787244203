// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Machines page.
// -----------------------------------------------------------------------------

/**
 * Sectors
 */




/**
 * Sectors detail
 */

.sector-detail-info{
  margin-bottom: 60px;
  
  @media (min-width: 992px){
    margin-bottom: 90px;
  }
  .icon-sector{
    width: 160px;
    margin-bottom: 30px;
  }
  .sector-text{
    font-size: 17px; 
    line-height: 1.6em;
  }
}



.list-applications{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }

  
  .box-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .item-info{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  
  .box-item .title{
    text-transform: none;
    font-weight: 600;
    font-size: 21px;
  }
}

/**
 * Applications detail
 */

.app-detail-section{
  margin-bottom: 60px;
  
  @media (min-width: 1200px){
    margin-bottom: 90px;
  }
  .fancy-title{
    margin-bottom: 40px;
  }
}

.app-detail-info{
  font-size: 17px;
}





