// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


footer{
  background-color: $color-dark;
  color: #aaa;
  h4, h5{
    color: #fff;
  }
  a{
    color: #aaa;
    @include on-event {
     color: #fff;
   }
  }
}

/**
 * Footer top
 */

.footer-top{
  padding: 3em 0;
  
  @media (min-width: 1200px){
     padding: 4em 0;
  }
  .title{
    margin-top: 20px;
    margin-bottom: 24px;
  }
}
.footer-logo{
  #logo{
    width: 224px;
    height: 30px; /* ie11 */
    margin: 20px 0;
  }
  #claim{
    max-width: 250px;
    height: 27px; /* ie11 */
  }
  @media (max-width: 1199px){
    border-bottom: 1px solid #444;
    padding-bottom: 30px;
    margin-bottom: 30px;
    
    #logo{
      margin-top: 0;
    }
  }
}
.footer-menu{
  li{
    margin-bottom: 10px;
  }
}


/**
 * Footer bottom
 */

.footer-bottom{
  border-top: 1px solid #444;
  padding: 24px 0;
  .list-inline li{
    padding: 0 10px;
    line-height: normal;
  }
}

.nav-social-footer{
  float: left;
  li{
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    display: block;
    padding: 0;
    font-size: 16px;
    color: #fff; 
     @include on-event {
       opacity:0.8;
     }
  }
} 

.nav-legal-footer{
  float: right;
  font-size: 14px;
}