.list-esg {
  li { margin-bottom: 30px !important; }
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 100, "GRAD" 0, "opsz" 48;
}

.esg-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 35px 30px;
  @media (min-width: 576px) {
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  }
}

.esg-circle {
  align-items: center;
  background-color: $color-primary-mid;
  background-color: rgba($color-primary-mid, 0.1);
  border-radius: 50%;
  height: 80px;
  width: 80px;
}

.esg-icon {
  color: $color-primary-mid;
  font-size: 3rem;
}

.page-section__esg {
  h3{
    margin-bottom: 1.5rem;
  }
  h4 {
    margin-bottom: 1.5rem;
    text-decoration: underline;
    margin-top: 0.5rem;
  }
  ul {
    padding-left: 1rem;
    margin-bottom: 1rem;
    li {
      list-style: disc;
      margin-bottom: .5rem;
    }
  }
  p {
    margin-bottom: 1rem;
  }
}


.slider-esg {
  &:before, &:after {
    content: none;
  }
  .slick-list {
   // overflow: visible;
  }
  .slick-track {
    display: flex;
    &:before, &:after {
      content: none;
    }
  }
  .slick-slide {
    float: none;
    height: auto;
  }
  &.slick-initialized {
    .slick-slide {
      display: flex;
    }
  }
}