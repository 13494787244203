.lazyframe {
    &[data-ratio="16:9"]:before { padding-top: 56.25%; }
    &[data-ratio="4:3"]:before { padding-top: 75%; }
//    &[data-ratio="1:1"]:before { padding-top: 100%; }
    &__video {
        &:after{
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 72px;
            height: 72px;
            margin-left: -36px;
            margin-top: -36px;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI3MnB4IiBoZWlnaHQ9IjcycHgiIHZpZXdCb3g9IjAgMCA3MiA3MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzIgNzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRkZGRkZGO30uc3Qxe2ZpbGw6IzM0M0E0MDt9PC9zdHlsZT48Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIzNiIgY3k9IjM2IiByPSIzNiIvPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik00NC4zLDM2YzAtMC4yLTAuMS0wLjQtMC4yLTAuNmwtMC4zLTAuMmwtMTQuMy04LjhjLTAuNS0wLjMtMS0wLjQtMS4zLTAuMmMtMC40LDAuMi0wLjYsMC43LTAuNiwxLjN2MTcuMWMwLDAuNiwwLjIsMS4xLDAuNiwxLjNjMC40LDAuMiwwLjgsMC4yLDEuMy0wLjJsMTQuMy04LjhsMC4zLTAuMkM0NC4zLDM2LjQsNDQuMywzNi4yLDQ0LjMsMzZ6Ii8+PC9zdmc+);
            background-position: center center;
            background-size: 100%;
            background-repeat: no-repeat;
            border: none;
            z-index: 4;
            transition: transform .2s ease;
        }
        &:hover:after{
            transform: scale(0.9);
        }        
    }
}
.lazyframe--custom {
    background: #bada55;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center; 
}
.lazyframe--custom .lazyframe__title {
    position: relative;
    width: 100%;
    display: block;
    font-size: 32px;
    font-family: arial;
    color: white;
}
.lazyframe--custom:before {
    width: auto;
}