// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Investors page.
// -----------------------------------------------------------------------------

.informes-descargas{
  margin-top: 60px;
  h4{
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

.table-informacion-publica{
    .table-custom.table{
     tbody > tr > td{
      text-align: center;
    }
  }
}


/**
 * Foro electronico
 */

.foro-registro{
  margin-bottom: 32px;
  p{
    font-size: 16px;
  }
}

.investors-forum-list{
  article{
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    padding-bottom: 40px;
    h2{
      font-size: 24px;
      a{
        color: $color-primary;
        @include on-event {
          color: $color-primary-mid;
        }
      }
    }
    &:last-child{
      border-bottom: none;
    }
  }
 
}
.forum-meta{
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;  
  display: -webkit-box;  
  display: -ms-flexbox;  
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 20px 0;
  margin-bottom: 60px;
  line-height: 1.3em;
  li{
    padding: 10px;
  }
}
.forum-article-info{
  margin-bottom: 60px;
}

.forum-article-questions{
  margin-bottom: 60px;
  article{
    margin-bottom: 30px;
  }
}
.f-question{
  .user{
    color: $color-primary;
    font-weight: 600;
      i{
      font-size: 14px;
    }
  }
}
.f-answer{
  margin-top: 14px;
  .user{
    color: $color-primary-mid;
    font-weight: bold;
  }
}
.fixed-height {
	height: 1600px;
}

