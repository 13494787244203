// -----------------------------------------------------------------------------
// This file contains all styles related to the pagination component.
// -----------------------------------------------------------------------------


.pagination{
	border-radius:0;
}
.pagination > li:first-child > a,
.pagination > li:last-child > a{
	border-radius:0;
}
.pagination > li:before{
	content:"";
}

.pagination > li > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover{
  //font-size: 14px;
  line-height: normal;
  padding: 10px 18px;
  border: 0;
  color: $color-primary;
  background-color:transparent;
}
.pagination > .active > a, 
.pagination > .active > a:focus, 
.pagination > .active > a:hover{
	background-color: transparent;
	color: $color-primary-mid;
  border: 1px solid $color-primary-mid;
}
