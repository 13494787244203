// -----------------------------------------------------------------------------
// This file contains all styles related to the sidebar of the site/application.
// -----------------------------------------------------------------------------

/**
 * #menu sidebar
 */

.sidebar-menu-heading{
  text-transform: uppercase;
  position: relative;
  margin: 0;
  padding: 18px 0px;
  border-bottom:2px solid #eee;
  font-size: 18px;
  
  &:after{
    background-color: $color-primary-mid;
    bottom: -2px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
  }
}

.sidebar-menu{
  font-size: 15px;
  li{
    line-height: 1.1em;
     &:first-child a{
       border-top: none;
    }
  }
  li.has-submenu{
    ul{
      font-size: 15px;
      li:last-child{
        margin-bottom: 10px;
      }
      a{
        border-top: none;
        color: $color-primary-mid;
        font-weight: normal;
        padding: 10px 10px;
      }
    }
  }
  a{
    border-top:1px solid #eee;
    padding: 18px 0px;
    color: $color-primary;
    font-weight: 600;
    display: block;
    
    @include on-event {
      color: $color-primary-mid;
    }
    &.active{
      color: $color-primary-light;
    }
   
    
  }
  .link-dropdown{
    a{
      padding-right: 50px;
    }
    .arrow{
      width: 50px;
      &:before{
        color: #333;
      }
    }
  }
}

aside.sidebar{
  background-color: #f6f6f6!important;
  &::before{
    background-color: #f6f6f6!important;
  }
  .box-form{
    background-color: #f6f6f6!important;
  }
}
