//
// Breadcrumbs
// --------------------------------------------------

#breadcrumbs{
  margin-bottom: 20px;
}

.breadcrumb-list{
  font-size: 14px;
  letter-spacing: 0.6px;
  list-style: none;

  > li {
    display: inline-block;
    color: $color-text;
    + li:before {
      content: "\f105 "; 
      font-family: $font-awesome;
      padding: 0 10px;
      color: #666;
    }
  }
  a{
    color: $color-primary;
    @include on-event {
      color: $color-primary-light;
    }
  }
  @include when-inside('.page-header'){
    li{
    color: lighten(#fff, 48%);
      a{
        color: #fff;
        @include on-event {
          color: $color-grey;
        }
      }
    }
  }
  @include when-inside('.page-header.transparent'){
    li{
    color: $color-text;
      a{
        color: $color-primary;
        @include on-event {
          color: $color-primary-light;
        }
      }
    }
  }
}
