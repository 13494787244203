//
// Dropdown menus
// --------------------------------------------------


// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top:   $caret-width-base dashed;
  border-top:   $caret-width-base solid \9; // IE8
  border-right: $caret-width-base solid transparent;
  border-left:  $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  position: relative;
  &.full-width {
    position: static;
  }
}

// The dropdown toggle (a)
.dropdown-toggle {
    &:after {
      font-family: FontAwesome;
      content: "\f107";
      font-weight: normal;
      //display:inline-block;
      font-size: inherit;
      margin-left: 8px;
      
    }
  } 

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 160px;
  padding: 0;
  margin: 0; // override default ul
  list-style: none;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  border:none;
  border-radius: 0;
  
  .full-width & {
      width: 100%;
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 12px 25px;
    clear: both;
    font-weight: normal;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: $dropdown-link-color;
    background-color: $dropdown-link-hover-bg;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $dropdown-link-active-bg;
  }
}


// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}


