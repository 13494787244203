// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.list-unstyled {
  list-style: none;
  li {
    &:before {
      content: none !important;
    }
  }
}
/**
 * Align elements vertically 
 */
.made-table {
  display: table;
  height: 100%;
  width: 100%;
}
.made-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.w-100 {
  width: 100%;
}

.text-0 {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.fs-17 {
  font-size: 1.0625rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

.fs-20 {
  font-size: 20px;
}
.fs-28 {
  font-size: 28px;
}
.fs-40 {
  font-size: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 { margin-bottom: 40px !important; }

.mb-100 {
  margin-bottom: 100px;
}

@media (min-width: 768px) {
  .mb-sm-60 { margin-bottom: 60px !important; }
}

@media (min-width: 992px) {
  // md
  .fs-md-40 {
    font-size: 40px;
  }
}

@media (min-width: 1200px) {
  .d-lg-flex {
    display: flex;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

