// -----------------------------------------------------------------------------
// This file contains all styles related to the cookies popup of the site/application.
// -----------------------------------------------------------------------------

#cookies-container {
  background-color: rgba(0,0,0,.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  #cookies-popup {
    background-color: rgba(48, 48, 48, 0.95);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    position: fixed;
    z-index: 10000;
    font-size: 16px;
    color: #c8c8c8;
    .container{
      max-width: 1024px;
    }
    a{
      color: #fff;
    }
    p {
      line-height: 1.7;
      margin-bottom: 1rem;
    }
    .texto {
      color: #fff;
    }
    .btn {
      font-weight: 700;
      padding: 3px 10px;
      border-radius: 0;
      font-size: 14px;
      margin-left: 10px;
      letter-spacing: 0.5pt;
      text-transform: uppercase;

      &.btn-link {
        font-weight: normal;
        text-transform: none;
      }
    }
  }
}

#cookies-configure {
  border: 1px solid $gray;
  padding: 2rem;
  margin-bottom: 1.5rem;
  h2{
    margin-top: 0;
  }
  ul {
    li {
      padding-left: 0;
      margin-bottom: 1rem;
      &::before {
        display: none;
      }
      .checkbox {
        margin: 0;
      }
    }
  }
}

#cookies-configure-container {
  background-color: rgba(0,0,0,.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1010;
  display: none;
  #cookies-configure {
    background-color: rgba(48, 48, 48, 0.95);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    border: 0;
    margin-bottom: 0;
    h2 {
      color: #FFF;
    }
  }
}

.no-video-cookies {
  position: relative;
  &.embed-responsive {
    background-color: #000;
  }
  .msg {
    position: absolute;
    width: 90%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    a {
      color: rgba(255,255,255,.8);
      &:hover {
        color: #FFF;
      }
    }
  }
}
