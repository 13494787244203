// -----------------------------------------------------------------------------
// This file contains styles that are specific to the accionistas pages.
// -----------------------------------------------------------------------------
.accionistas{
	.accionistas-link{
		font-weight: 800;
	}
}
#videoconferencia{
	.text{
		margin-bottom: 4rem;
	}
	iframe{
		width: 100%;
		height: 650px;
		border: 2px solid #dedede;
	}
}
.hechos-relevantes{
	h2{
		color: $color-azul;
    	font-size: 25px;
    	line-height: 29px;
    	margin: 2rem 0 1.5rem;
    	padding: 2rem 0 0;
    	&:not(:first-child){
    		border-top: 1px solid #dedede;
    	}
	}
	.text ul li:before{
		color: $color-grey;
	}
	p, ul{
		margin-bottom: 10px;
	}
	a{
		font-weight: 600;
	}
}