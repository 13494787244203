.newsletter{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
  margin-bottom: 0;
}
.modal-newsletter{
  .modal-content{
    border-radius: 0px;
    padding: 1rem 2rem;
  }
}