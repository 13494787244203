// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: normal;
  padding: 12px 30px;
  border-radius: 1px;
  letter-spacing: 0.02em;
  position: relative;
}



// Alternate buttons
// --------------------------------------------------

.btn-primary {
  @include button-variant(#fff, $color-primary, transparent);
  @include on-event {
    color: #fff;
    background-color: $color-primary-mid;
    border-color: transparent;
  }
}
.btn-primary-mid {
  @include button-variant(#fff, $color-primary-mid, transparent);
  @include on-event {
    color: #fff;
    background-color: $color-primary-light;
    border-color: transparent;
  }
}
.btn-primary-light {
  @include button-variant(#fff, $color-primary-light, transparent);
  @include on-event {
    color: #fff;
    background-color: $color-primary-mid;
    border-color: transparent;
  }
}

.btn-stroke {
  border-color: $color-primary;
  color:  $color-primary;

  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.45s cubic-bezier(0.4, 0, 0.2, 1) 75ms;
  > * {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
  
  &:before{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
  }
  
  &:after{ 
    content: "";
    background-color: $color-primary;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: left center 0;
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 75ms, -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 75ms;
  }
}

.btn-label {
    display: inline-block;
    position: relative;
    transform: translateX(0px);
    transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0s, -webkit-transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    z-index: 2;
}

.btn-stroke:hover {
    color: #fff;
    transition-delay: 0s;
  
     &:after{
      transform: scaleX(1);
      transition-delay: 0s;
     }
  
    .btn-label {
      transform: translateX(0.5rem);
      transition-delay: 75ms;
    }
}

.btn-stroke.-white {
    border-color: #fff;
    color: #fff;
  
    &:after{
      background-color: #fff;
    }
    &:hover {
      color: $color-primary;
    }
}



// Button Sizes
// --------------------------------------------------

.btn-lg {
  padding: 20px 33px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.6px;
  
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}
.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}


.btn-big-icon{

  padding: 32px;
  //color: $color-primary;
  margin: 0 auto 60px;
  position: relative;
  line-height: 1.3em;
  //font-size: 17px;
  font-weight: 600;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid $color-primary-mid;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 15px;
  box-shadow: 0 5px 15px rgba(50, 50, 93, 0.1), 0 2px 8px rgba(0, 0, 0, 0.07);
  
  .icon{
    height: 32px;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  }
  span{
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 0 0 0 30px;
  }
  &:hover{
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  }
}




