// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Services page.
// -----------------------------------------------------------------------------

/**
 * Services
 */

#services-intro{
  &.page-section{
    padding-bottom: 0;
  }
  @media (min-width: 992px) {
    .lead{
        padding-right: 40px;
    }
  }
}

#services-list{

  .box-img{
    margin-bottom: 30px;
    
    @media (max-width: 600px){
      width: 100%;
    }
  }
}

/**
 * Services detail
 */

#app-services{
  margin-top: 90px;
}

.list-app-services{
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    &.row{
        &:before, &:after{
          content: normal; // IE doesn't support `initial`
        }
    }
    
  >li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
   
    @media (max-width: 600px){
      width: 100%;
    }
    @media (min-width: 1200px){
      padding: 30px;
    }
  }
  h3{
    font-size: 24px;
    font-weight: 600;
  }
  >li li{
    margin-bottom: 3px;
  }
}


.sales-service{
  margin-bottom: 40px;
  h3 {
    text-transform: none
  }
}