/* Scss Document */

/**
 * Hero Section
 *
 * maquinas.php, aplicaciones.php
 *
 */

.hero-section{
  
  a{
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    margin-bottom: 40px;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  }
  
  .figcaption{
    position: relative;
    width: 100%;
    padding: 1.5em;
    background-color: $color-primary-mid; 
    color: #fff;
    @include transition(all 0.3s ease);
    
    h2, h3{
      color: #fff;
      margin: 0;
    }
    .entry{
      margin-top: 24px;
      p{
        margin-bottom: 0;
      }
    }
    
  }
  /* media query */
  @media (min-width: 768px){
    a{
      max-height: 520px;
      &:hover{
        .figcaption{
          transform: translateX(20px);
        }
      }
    }
    .figcaption{
        position: absolute;
        top: 0;
        right: 0;
        padding: 4em 4em 4em 1em;
        height: 100%;
        background-color: rgba(0, 111, 180, 0.72);
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
        padding: 1em 2em 1em 0;
        width: 34%;

         &:before{
          background: $color-primary;
          bottom: 0;
          content: "";
          display: block;
          left: 0;
          opacity: 0.72;
          position: absolute;
          right: 0;
          top: 0;
          z-index: -1;
        }
        &:after{
          background: rgba(0, 0, 0, 0) url("../images/waves.svg") no-repeat scroll 0 0 / 206px 100%;
          bottom: 0;
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          left: -206px;
          top: 0;
          width: 206px;
          z-index: -1;
        }
    }

  }
  @media (min-width: 1200px){
      .figcaption{
        padding: 4em 4em 4em 1em;
        width: 30%;
      }
    }

}

/**
 * Box item
 *
 * maquinas-subcategoria.php, maquinas-detalle.php, aplicaciones-detalle.php, noticias.php, distribuidores-detalle.php
 *
 */

.box-item {
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  
  a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    overflow: hidden;
    position: relative;
  }
  
  figure{
    display: block;
    position: relative;
    overflow:hidden;
    width: 100%;
    z-index: 9;
    img{
      backface-visibility: hidden;
      transform: scale(1);
      transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1) 0s;
      margin:0 auto;
    }
  }
  .item-info{
    padding: 24px;
    background: #fff;
    border-bottom: 2px solid #E8E8E8;
    border-top: 1px solid #eee;
    .title{
      font-weight: 700;
      font-size: 19px;
      line-height: 1.3;
      text-transform: uppercase;
      margin: 0; 
    }
    .entry{
      font-size: 15px;
      line-height: 1.4em;
      margin-top: 10px;
      color: $color-text;
      p{ margin:0;}
    }
  }
  
}
.box-item.col-xs-6 {
  @media (max-width: 480px){
    width: 100%;
  }
}


/**
 * Box-img
 *
 * servicios.php
 *
 */



.box-img{
   color: #ffffff;

  .figcaption{
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    padding: 2rem 3rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    color: #fff;
    font-size: 2rem;
    line-height: (24/20);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,.45)));
    background: -webkit-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.45));
    background: -o-linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.45));
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.45));
    z-index: 10;
    h2{
      color: #fff;
    }
  }
}



