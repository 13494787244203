.block {
  + .block {
    margin-top: 50px;
  }
  &.block-2 {
    .img-top {
      margin-bottom: 30px;
    }
    .img-bottom {
      margin-top: 30px;
    }
  }
  &.block-3 {
    .sales-service {
      padding: 30px;
      margin-bottom: 0;
    }
    + .block-3 {
      margin-top: 0;
    }
  }
}