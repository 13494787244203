// -----------------------------------------------------------------------------
// This file contains all styles related to the tables of the site/application.
// -----------------------------------------------------------------------------

.table > tbody > tr > td, 
.table > tbody > tr > th, 
.table > tfoot > tr > td, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > thead > tr > th{
  vertical-align: middle;
  background-color: #F1F1F1;
  border: none;
  padding: 10px;
}


.table-custom.table{
  margin-top: 30px;
  border-collapse: initial;
  border-spacing: 2px;
  font-size: 15px;
  
    thead > tr > th{
      text-align: center;
      color: #fff;
      background-color: #2b4a76;
    }
    tbody > tr > th{
      text-align: left;
      font-weight: 600;
      padding: 10px 20px;
      color: $color-text;
      
      &.title{
        background-color: #e0e1e4; 
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.1em;
        font-weight: bold;
        color: $color-primary;
      }
    }
   
}









